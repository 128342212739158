import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Crisp } from "crisp-sdk-web";

Sentry.init({
    dsn: "https://e27dd47eec9b99f05a58da8b533c47ee@o4505766204080128.ingest.us.sentry.io/4508161287061504",
    integrations: [
        Sentry.replayIntegration()

    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
  });
  

Crisp.configure('231ee8bb-41b1-46e7-ac46-be626e53c0db', {
    // autoload: false
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />‡
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
