import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { notifs } from '../../composents/Notifs';
import axios from 'axios';
import { useMetaTags } from 'react-metatags-hook';
import { Crisp } from "crisp-sdk-web";

import Auteur from '../modules/Auteur';


const LincontournableDuRock = () => {

    const dispo = false; // true si le livre est disponible, false si le livre est en rupture de stock

    const [book, setBook] = useState();

    const apiKeyBrevo = process.env.REACT_APP_BREVO;

    const [askDispo, setAskDispo] = useState(false);

    const livreTitre = "L'incontournable du Rock";
    const livreDescription = "L’incontournable du Rock est le livre de tablatures de référence, pour tous les guitaristes passionnés. Retrouvez dans ces pages, une centaine de titres de légende et jouez vos morceaux préférés avec votre guitare pour seulement";


    useMetaTags({
        title: livreTitre + ' - Xavier Flament',
        description: livreDescription,
        charset: 'utf8',
        lang: 'fr',
        metas: [
          { name: 'keywords', content: 'rock, musique, guitare, batterie, histoire, artiste, album, concert, groupe, riff, solo, blues, pop, métal, composition, musicien, influences, décennie, underground, mainstream, amplification, critique musicale, discographie, chronologie, festivals, scène, enregistrement, sonorité, tube, classique, évolution, culture musicale, label, producteur, industrie musicale, électrique, acoustique, performance, légende, mythologie, rythme, vinyle, compilation, répertoire, biographie, analyse, instruments, voix, studio, live, mouvement musical, innovation, tournée' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: livreTitre + ' - Xavier Flament' }
        ]
    });

    const addContact = async(email, prenom, listID = 3) => {
        const response = await axios({
            method: 'post',
            url: 'https://api.brevo.com/v3/contacts',
            data: JSON.stringify({
                email: email,
                // ext_id: 20,
                attributes: {
                    PRENOM: prenom, 
                    // COUNTRIES: ['France']
                },
                emailBlacklisted: false,
                smsBlacklisted: false,
                listIds: [listID],
                updateEnabled: false,
                //smtpBlacklistSender: ['user@example.com']
                }),
            headers: {
                'content-type': 'application/json',
                'api-key': apiKeyBrevo
            }
          })
          .then(response => {
            setAskDispo(true);
            Crisp.user.setEmail(email);
            Crisp.user.setNickname(prenom);

            Crisp.session.pushEvent("subscription", { email: email, prenom: prenom });
            notifs('Votre demande à bien été enregistré. Si ce livre est à nouveau disponible vous serez informé par email en priorité', 'success');
          })
          .catch(error => {
            notifs(error.response.data.message, 'error');
            });
    };



    const addList = async(email, listID = 3) => {
        const response = await axios({
            method: 'post',
            url: 'https://api.brevo.com/v3/contacts/lists/'+listID+'/contacts/add',
            data: JSON.stringify({
                emails: [
                   email
                  ]
                }),
            headers: {
                'content-type': 'application/json',
                'api-key': apiKeyBrevo
            }
          })
          .then(response => {
            setAskDispo(true);
            Crisp.session.pushEvent("subscription", { email: email });
            notifs('Votre demande à bien été enregistré. Si ce livre est à nouveau disponible vous serez informé par email en priorité', 'success');

          })
          .catch(error => {
            notifs(error.response.data.message, 'error');
        });
    };

    const buttonDispo = async(e) => {
        e.preventDefault();
        const prenom = e.target[0].value;
        const email = e.target[1].value;

        if (email && email != null) {

            // On créer le contact et on l'ajoute à la liste
            try {
                addContact(email, prenom);
            }
            catch (error) {
                console.error(error);
            }

            // Le contact existe déjà alors on l'ajoute juste à la liste
            if (!askDispo)
            {
                try {
                    addList(email);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
        else
        {
            notifs('Veuillez renseigner tous les champs', 'error');
        }
    };
    
    useEffect(() => {
        // getBook();
    }, []);

    return (
        <div>
            <Header />
            
            <section className="banner-main-3" id="banner-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-md-6">
                            <div className="main-banner-content">
                                <span className="subtitle">{dispo ? 'Désormais disponible' : 'Ce livre est actuellement en rupture de stock !'}</span>
                                <h1>
                                    {livreTitre}
                                </h1>
                                <p className="mb-3 mt-4">{livreDescription}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="banner-img">
                                <img src="'./../../img/books/IncontounrableRock.jpg" alt={livreTitre} className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>


            {!dispo && 
            <section className="cta-home" id="demo">
                <div className="container">
                    <div className="row justify-content-center bg-main">
                        <div className="col-lg-6">
                            <h2 className="text-lg">Ce livre est actuellement en rupture de stock !</h2>
                            <p className="mt-3 mb-0">Recevez un email lorsque ce livre sera à nouveau disponible dans notre boutique en ligne et recevez toutes les mises à jour de la part de notre équipe.</p>
                        </div>

                        <div className="col-lg-6">
                            <form action="#" className="subscribe-form" onSubmit={buttonDispo}>
                                <div className="form-group">
                                    <input type="text" name="Prenom" className="form-control" placeholder="Prénom"/>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="Email" className="form-control" placeholder="Email"/>
                                </div>

                                <button type="submit" className="btn btn-main-2">Tenez moi informer</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </section>}

            <section className="features" id="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="heading">
                                <span>features</span>
                                <h2>Le Rock à ses titres mythiques ? Les guitaristes ont leur livre</h2>
                                <p>L’incontournable du Rock est une vraie bible pour tous les amoureux de guitare. Par ses tablatures, ce livre est accessible à tous les niveaux et vous suivra partout. .</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-music-alt"></i></div>
                                <h5>800 pages de Rock</h5>
                                <p>L’Incontournable du Rock est le premier livre à proposer autant de tablatures. Résultats, retrouvez 1000 pages 100 % Rock dédiées spécialement à votre guitare et votre passion de la musique ! Grâce à son papier particulier, cette bible du rock ne mesure que 3,5 centimètres d’épaisseur, afin de vous permettre de l’emporter partout avec vous.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-server"></i></div>
                                <h5> Tous niveaux </h5>
                                <p>Les titres de ce livre du rock sont variés et adaptés à tous les niveaux. Guitare en main, vous aurez accès à des tablatures simples mais également plus complexes. L’Incontournable du Rock pourra ainsi suivre votre progression.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="feature-item">
                            <div className="feature-icon"><i className="ti-book"></i></div>
                            <h5>Format de poche </h5>
                            <p>Ce livre de tablatures dispose d’un format poche d’une demie feuille A4. L’Incontournable du Rock ne quittera plus jamais votre guitare. Certains morceaux demandent la présence de plusieurs guitaristes, ce qui vous permettra également de jouer avec vos amis.</p>
                        </div>
                    </div>
                        
                    </div>
                </div>
            </section>

            <section className="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="book-preview">
                                <img src="../img/PremieresMelodies/kindle.png" className="background-device img-fluid" alt=""/>
                                <div className="owl-book owl-carousel owl-theme" style={{opacity: 1, display: 'block'}}>
                                    <div className="book-item">
                                        <img src="./../../img/TabsRock/Tabs02.png" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6" id="about">
                            <div className="section-heading book-info mt-lg-0 pl-4">
                                <h2 className="text-lg mb-4">Vos artistes et chansons préférées</h2>
                                <p className="lead">Avec le livre « L’incontournable du rock », prenez votre <strong>guitare</strong> et retrouvez les tablatures des artistes les plus mythiques de tous les temps : Pink Floyd, David Bowie, Muse, les Beatles, Queen, les Rolling Stones, etc. Tous n’attendent plus que vous !</p>

                                {/* <div className="about-features mt-5">
                                    <div className="about-item mb-40">
                                        <h5>Targetting Vission</h5>
                                        <p>Fugit ratione, repellendus, dignissimos, ducimus voluptatem, quos. Consequatur non eum facilis, ex repellendus, vitae debitis.</p>
                                    </div>
                                    <div className="about-item mb-40">
                                        <h5>Powerful Knowledge</h5>
                                        <p>Debitis ab ipsum a autem sit ducimus voluptatem, quos. Consequatur non eum facilis, ex repellendus, vitae debitis.</p>
                                    </div>
                                    <div className="about-item">
                                        <h5>Evergreen Marketing Goals</h5>
                                        <p>Possimus debitis ab ipsum a autem sit ducimus voluptatem, quos. Consequatur non eum facilis, ex repellendus, vitae debitis.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>	
{/* 
            <section className="cta-3">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="cta-content text-center">
                                <span>Signup now to receive latest news & exclusive ticket offers.</span>
                                <h2>Don’t miss event of Grand Central Publishing.</h2>
                                <a href="#" target="_blank" className="btn btn-white mt-2"> <i className="fab fa-amazon mr-2"></i> on Amazon</a>
                                <a href="#" target="_blank" className="btn btn-main-2 mt-2 ml-lg-2"> <i className="fab fa-google-play mr-2"></i> Playstore</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="section chapter-2" id="chapter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="heading text-center">
                                <span className="text-color">Featured topic</span>
                                <h2>What's inside the book</h2>
                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="chapter-inner">
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Design principles</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Visual hierarchy</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Powerful UI</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Digital Marketing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div className="chapter-inner">
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>White space</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>UI design</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Marketing Thoughts</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Learning Materials</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div className="chapter-inner">
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Typography</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Web Design </h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Color theory</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Design  theory</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="section counter-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item mb-5 mb-lg-0">
                                <i className="ti-world"></i>	
                                <h2 className="count">23,500</h2>
                                <p>Copies Sold</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item  mb-5 mb-lg-0">
                                <i className="ti-face-smile"></i>	
                                <h2 className="count">53,246</h2>
                                <p>Cup Of Coffee</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item  mb-5 mb-lg-0">
                                <i className="ti-bookmark-alt"></i>	
                                <h2 className="count">32,456</h2>
                                <p>Copies Released</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item mb-5 mb-lg-0">
                                <i className="ti-heart"></i>	
                                <h2 className="count">45,522</h2>
                                <p>Happy Readers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            
            <Auteur />

            {/* <section className="featured-client section-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="heading text-center">
                                <span className="text-color">Featured topic</span>
                                <h2>Featured by them. </h2>
                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center border p-4">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <img src="../img/about/award-2.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-2.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-3.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-4.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-2.png" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            
        
            <Footer />
        </div>
    );
};

export default LincontournableDuRock;