
import React from 'react';

const Header = () => {
    return (
        <div>
            <header className="header-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-6  d-none d-lg-block d-sm-block">
                            <div className="header-contact">
                                <span><i className="fa fa-book"></i> Des livres originaux pour toute la famille </span>
                                <a href="mailto:contact@100asavoir.com"><i className="fa fa-envelope"></i> contact@100asavoir.com</a>
                                <a href="tel:+33970465606"><i className="fa fa-phone"></i>+33 9 70 46 56 06</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="header-socials text-center">
                                <ul className="mb-0">
                                    <li >
                                        <a href="https://www.facebook.com/100aSavoir/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.amazon.fr/sp?ie=UTF8&seller=A2K6JPHK7CB9GK&asin=2955047880&ref_=dp_merchant_link&isAmazonFulfilled=1" target="_blank"><i className="fab fa-amazon"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCAeDL8IJB_J2QKEv0X-IM8A" target="_blank"><i className="fab fa-youtube"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main-navigation site-header main-nav-3" id="mainmenu-area">
                <div className="container align-items-center">
                    <nav className="navbar navbar-expand-lg ">
                        <a className="navbar-brand smoth-scroll" href="./../">
                            <img src="../img/logo-mini.webp" alt="100àSavoir" style={{height:'55px'}} className="img-fluid w-100"/>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="ti-menu-alt"></span>
                        </button>
                        <div className="collapse navbar-collapse text-center text-lg-left" id="navbarCollapse">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item">
                                    <li className="nav-item ">
                                        <a href="./../" className="nav-link smoth-scroll">
                                            Accueil
                                        </a>
                                    </li>
                                </li>
                                <li className="nav-item ">
                                    <a href="#" className="nav-link smoth-scroll">
                                        Livres
                                    </a>
                                    <ul className="submenu">
                                        <li><a href="/tabs-mes-premieres-melodies" className="nav-link smoth-scroll">Mes Premières Mélodies</a></li>
                                        <li><a href="/tabs-incontournable-du-rock" className="nav-link smoth-scroll">L'incontournable du Rock</a></li>
                                        <li><a href="/100-morts-stupides" className="nav-link smoth-scroll">100 morts les plus stupides</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item ">
                                    <a href="/a-propos" className="nav-link smoth-scroll">
                                        À propos
                                    </a>
                                </li>
                                {/* <li className="nav-item ">
                                    <a href="/blog" className="nav-link smoth-scroll">
                                        Blogs
                                    </a>
                                </li> */}
                                {/* <li className="nav-item ">
                                    <a href="/contactez-nous" className="nav-link smoth-scroll">
                                        Contact
                                    </a>
                                </li> */}
                            </ul>

                            <a href="https://www.amazon.fr/dp/2955047880?m=A2K6JPHK7CB9GK&tag=100asavoir-21" target="_blank" className="btn btn-border btn-small ml-3">Acheter</a>
                        </div> 
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Header;