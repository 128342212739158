import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Pages
import MesPremieresMelodies from './pages/livres/MesPremieresMelodies';
import LincontournableDuRock from './pages/livres/LincontournableDuRock';
import Confidentialites from './pages/Confidentialites';
import MentionsLegales from './pages/MentionsLegales';
import About from './pages/About';
import MortsStupides from './pages/livres/MortsStupides';
import Comptines from './pages/Comptines.jsx';
import { Crisp } from "crisp-sdk-web";


// css
// import './App.css';
import './plugins/bootstrap/css/bootstrap.min.css';
import './plugins/animate-css/animate.css';
import './plugins/fontawesome/css/all.css';
import './plugins/themify/css/themify-icons.css';
import './plugins/magnific-popup/magnific-popup.css';
import './plugins/slick-carousel/slick/slick.css';
import './plugins/owl-carousel/owl.carousel.min.css';
import './plugins/owl-carousel/owl.theme.default.min.css';
import './css/style-2.css';
import './css/responsive.css';

function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" element={<MesPremieresMelodies />} />
        <Route path="/tabs-mes-premieres-melodies" element={<MesPremieresMelodies />} />
        <Route path="/tabs-incontournable-du-rock" element={<LincontournableDuRock />} />
        <Route path="/100-morts-stupides" element={<MortsStupides />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/comptines" element={<Comptines />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/confidentialite" element={<Confidentialites />} />
        {/* <Route path="/redirection/:id" element={<Redirection />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
