import React from 'react';

const Footer = () => {
    return (
        <div>
            <section className="footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4  col-md-5 right-border">
                            <div className="footer-about">
                                <h4>100àSavoir</h4>
                                <p>100àSavoir est un éditeur qui vous propose une multitude de livres uniques et inédits autour d'un sujet original ou éducatif.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="footer-widget pl-lg-5">
                                <div className="contact-item">
                                    <h5 className="mb-2">Appelez-nous</h5>
                                    <p>+33 9 70 46 56 06 </p>
                                </div>
                                <div className="contact-item">
                                    <h5 className="mb-2">Contactez-nous</h5>
                                    <p>contact@100asavoir.com</p>
                                </div>
                                <ul className="list-inline footer-socials mb-0">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/100aSavoir/" target="_blank"><i className="ti-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.youtube.com/channel/UCAeDL8IJB_J2QKEv0X-IM8A" target="_blank"><i className="ti-youtube"></i></a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a href="https://www.amazon.fr/sp?ie=UTF8&seller=A2K6JPHK7CB9GK&asin=2955047880&ref_=dp_merchant_link&isAmazonFulfilled=1" target="_blank"><i className="ti-brand-amazon"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* 
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <h5>Nos livres</h5>
                                <ul className="list-unstyled">
                                    <li><a href="/tabs-mes-premieres-melodies">Mes Premières Mélodies</a></li>
                                    <li><a href="/tabs-incontournable-du-rock">L'incontournable du Rock</a></li>
                                    <li><a href="/100-morts-stupides">100 morts stupides</a></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget footer-links">
                                <a href="/mentions-legales">Mentions légales </a>
                                <a href="/confidentialite">Politique de confidentialité</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer-btm">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <p className="footer-copy text-white-50 mb-0">
                                &copy; Copyright <a className="current-year" href="http://fxweb.fr" target="_blank" rel="noopener noreferrer">FX Web</a> Tous droits réservés 2016-{new Date().getFullYear()}.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <a className="scroll-to-top smoth-scroll" href="#top-header">
                <i className="ti-angle-up"></i>
            </a>
        </div>
    );
};

export default Footer;