import React from 'react';
import Header from './includes/Header';
import Footer from './includes/Footer';
import { useMetaTags } from 'react-metatags-hook';


const MortsStupides = () => {

    useMetaTags({
        title: 'À propos de nous - 100àSavoir',
        description: '100àSavoir est une petite maison d\'édition. Elle valorise le 100% français, ainsi tous nos auteurs, notre équipe et nos prestataires (imprimeurs, promotions, etc.) sont Français et produisent en France. Nous valorisons les jeunes auteurs de fiction (ou non) en produisent peu de livres annuellement, mais en les accompagnants tout au long de la vie de leur livre.',
        charset: 'utf8',
        lang: 'fr',
        metas: [
          { name: 'keywords', content: 'édition, français, auteur, livre, production, maison d\'édition, fiction, accompagnement, création, entrepreneuriat, territoire, économie locale, jeunesse, culture, publication, promotion, impression, littérature, innovation, proximité, talent, développement, soutien, création littéraire, identité, made in france, artisan, expertise, passion, écriture, valeur, aventure éditoriale, découverte, créativité, excellence, local, patrimoine, projet, dynamisme, émergence, narratif, inspiration, écosystème, engagement, qualité, transmission, proximité, dialogue, collaboration, reconnaissance' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: 'Qui sommes nous ?' }
        ]
    });

    return (
        <div>
            <Header />
            
            <section className="banner-main-3" id="banner-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-md-6">
                            <div className="main-banner-content">
                                <span className="subtitle">Qui sommes nous</span>
                                <h1>
                                    À propos de nous
                                </h1>
                                <p className="mb-3 mt-4">100àSavoir est une petite maison d'édition. Elle valorise le 100% français, ainsi tous nos auteurs, notre équipe et nos prestataires (imprimeurs, promotions, etc.) sont Français et produisent en France. Nous valorisons les jeunes auteurs de fiction (ou non) en produisent peu de livres annuellement, mais en les accompagnants tout au long de la vie de leur livre.</p>

                                <div className="btn-container">
                                    <a href="https://100asavoir.com/index.php?Page=Amazon&id=2" target="_blank" el="noreferrer" className="btn btn-main-2 ml-0 ml-lg-2 ">Acheter maintenant</a>

                                    <a href="https://www.amazon.fr/kindle-dbs/hz/signup?tag=100asavoir-21" target="_blank" el="noreferrer" className="btn btn-main ml-0 ml-lg-2 ">Inclus dans l'abonnement Kindle</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="banner-img">
                                <img src="../img/apropos.jpg" alt="Les 100 morts les plus stupides" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

            <section className="author section" id="author">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="../img/QuiSommesNous1.jpg" alt="Qui sommes nous" className="img-fluid w-100"/>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="author-info pl-4 mt-5 mt-lg-0">
                                <div className="heading">
                                    <h2>Qui sommes nous</h2>
                                    <div className="follow">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a href="https://www.amazon.fr/stores/Xavier-Flament/author/B00OLZZQ04?tag=100asavoir-21" target="_blank"><i className="ti-amazon"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://www.facebook.com/100aSavoir/"><i className="ti-facebook"></i></a>
                                                <a href="https://www.youtube.com/channel/UCAeDL8IJB_J2QKEv0X-IM8A"><i className="ti-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <p className="mb-4">Notre petite équipe est située dans l'Oise à 30min de Paris. Nous sommes passionnés par des sujets originaux c'est pourquoi nous publions des livres exclusifs. Nous gérons toute la vie de nos livres en faisant appel à différents métiers. Nous établissons notre propre promotion et faisons appel au même imprimeur (Copy-Média) depuis déjà 3 ans.</p>
                                {/* <img src="../img/about/2.png" alt="" className="img-fluid"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

{/* 
            <section className="author section" id="author">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="author-info pl-4 mt-5 mt-lg-0">
                                <div className="heading">
                                    <span className="text-color">À propos de l'auteur</span>
                                    <h2>Qui sommes nous</h2>
                                    <div className="follow">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a href="https://www.amazon.fr/stores/Xavier-Flament/author/B00OLZZQ04?tag=100asavoir-21" target="_blank"><i className="ti-amazon"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <p className="mb-4">Notre petite équipe est située dans l'Oise à 30min de Paris. Nous sommes passionnés par des sujets originaux c'est pourquoi nous publions des livres exclusifs. Nous gérons toute la vie de nos livres en faisant appel à différents métiers. Nous établissons notre propre promotion et faisons appel au même imprimeur (Copy-Média) depuis déjà 3 ans.</p>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="../img/auteur.jpg" alt="Xavier Flament" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="features" id="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="heading">
                                <h2>Nos Services exclusifs</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-truck"></i></div>
                                <h5>Livraison gratuite</h5>
                                <p>Conformément à la loi française, le 0,01€ minimum de frais de livraison obligatoire est intégré dans le prix. Ainsi toutes nos livraisons sont gratuites en France métropolitaine.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-money"></i></div>
                                <h5>Satisfait ou 100% remboursé</h5>
                                <p>Parce que la satisfaction client est notre priorité absolue 100àSavoir et le seul éditeur à vous rembourser intégralement sans justificatif si la qualité du livre ne vous convient pas.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-flag"></i></div>
                                <h5>Fabriqué en France </h5>
                                <p>Tous nos livres sont imprimés en France chez un imprimeur français. Nous faisons confiance à CopyMédia depuis plusieurs années pour son savoir-faire et son engagement dans l'écologie.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-comment"></i></div>
                                <h5>Service après-vente </h5>
                                <p>Notre service après-vente et à votre disposition pour toute information du lundi au samedi de 10h30 à 19h30 sans interruption (appel non surtaxé).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        
            <Footer />
        </div>
    );
};

export default MortsStupides;