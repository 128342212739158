import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { notifs } from '../../composents/Notifs';
import axios from 'axios';
import Auteur from '../modules/Auteur';
import { useMetaTags } from 'react-metatags-hook';
import { Crisp } from "crisp-sdk-web";


const MesPremieresMelodies = () => {

    const dispo = false; // true si le livre est disponible, false si le livre est en rupture de stock

    const apiKeyBrevo = process.env.REACT_APP_BREVO;


    const livreTitre = "Le livre pour les petits et pour les grands";
    const livreDescription = "Mes Premières Mélodies est un livre de comptines pour enfants. Il contient des tablatures simplifiées pour apprendre la guitare, des accords pour accompagner au chant ou à la guitare, des illustrations adaptées aux enfants et des coloriages. Le livre propose également des versions audio pour diffuser les comptines chez vous.";


    useMetaTags({
        title: 'Mes Premières Mélodies - Xavier Flament',
        description: livreDescription,
        charset: 'utf8',
        lang: 'fr',
        metas: [
          { name: 'keywords', content: 'mélodie, musique, enfant, éveil musical, partition, notes, rythme, chanson, comptine, berceuse, solfège, voix, instrument, piano, apprentissage, éducation musicale, son, harmonie, tempo, mesure, clé de sol, gamme, portée, do ré mi, écoute, chant, découverte, initiation, pédagogie, ludique, débutant, musical, expression, créativité, sensibilité, orchestration, acoustique, répétition, mémorisation, concentration, développement, motricité, audition, répertoire, divertissement, interprétation, culture musicale, perception, émotion, apprentissage' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: 'Mes Premières Mélodies - Xavier Flament' }
        ]
    });

    const [askDispo, setAskDispo] = useState(false);

    const addContact = async(email, prenom, listID = 3) => {
        const response = await axios({
            method: 'post',
            url: 'https://api.brevo.com/v3/contacts',
            data: JSON.stringify({
                email: email,
                // ext_id: 20,
                attributes: {
                    PRENOM: prenom, 
                    // COUNTRIES: ['France']
                },
                emailBlacklisted: false,
                smsBlacklisted: false,
                listIds: [listID],
                updateEnabled: false,
                //smtpBlacklistSender: ['user@example.com']
                }),
            headers: {
                'content-type': 'application/json',
                'api-key': apiKeyBrevo
            }
          })
          .then(response => {
            setAskDispo(true);
            Crisp.session.pushEvent("subscription", { email: email, prenom: prenom });
            notifs('Votre demande à bien été enregistré. Si ce livre est à nouveau disponible vous serez informé par email en priorité', 'success');
          })
          .catch(error => {
            notifs(error.response.data.message, 'error');
            });
    };



    const addList = async(email, listID = 3) => {
        const response = await axios({
            method: 'post',
            url: 'https://api.brevo.com/v3/contacts/lists/'+listID+'/contacts/add',
            data: JSON.stringify({
                emails: [
                   email
                  ]
                }),
            headers: {
                'content-type': 'application/json',
                'api-key': apiKeyBrevo
            }
          })
          .then(response => {
            setAskDispo(true);
            Crisp.session.pushEvent("subscription", { email: email });
            notifs('Votre demande à bien été enregistré. Si ce livre est à nouveau disponible vous serez informé par email en priorité', 'success');
          })
          .catch(error => {
            notifs(error.response.data.message, 'error');
        });
    };

    const buttonDispo = async(e) => {
        e.preventDefault();
        const prenom = e.target[0].value;
        const email = e.target[1].value;

        if (email && email != null) {

            // On créer le contact et on l'ajoute à la liste
            try {
                addContact(email, prenom, 29);
            }
            catch (error) {
                console.error(error);
            }

            // Le contact existe déjà alors on l'ajoute juste à la liste
            if (!askDispo)
            {
                try {
                    addList(email, 29);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
        else
        {
            notifs('Veuillez renseigner tous les champs', 'error');
        }
    };

    const buttonDemo = async(e) => {
        e.preventDefault();
        const prenom = e.target[0].value;
        const email = e.target[1].value;

        if (prenom && email && prenom != null && email != null) {

            const response = await axios({
                method: 'post',
                url: 'https://api.brevo.com/v3/contacts',
                data: JSON.stringify({
                    email: email,
                    // ext_id: 20,
                    attributes: {
                        PRENOM: prenom, 
                        // COUNTRIES: ['France']
                    },
                    emailBlacklisted: false,
                    smsBlacklisted: false,
                    listIds: [13],
                    updateEnabled: false,
                    //smtpBlacklistSender: ['user@example.com']
                    }),
                headers: {
                    'content-type': 'application/json',
                    'api-key': apiKeyBrevo
                }
              })
              .then(response => {
                notifs('Votre demande de démonstration a bien été ajouté. Un email a été envoyé à ' + email, 'success');
              })
              .catch(error => {
                notifs(error.response.data.message, 'error');
                });
        }
        else
        {
            notifs('Veuillez renseigner tous les champs', 'error');
        }
    };


    useEffect(() => {
        // getBook();
          
    }, []);

    return (
        <div>
            <Header />
            
            <section className="banner-main-3" id="banner-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-md-6">
                            <div className="main-banner-content">
                                <span className="subtitle">{dispo ? 'Désormais disponible' : 'Ce livre est actuellement en rupture de stock !'}</span>
                                <h1>
                                    {livreTitre}
                                </h1>
                                <p className="mb-3 mt-4">{livreDescription}</p>

                                <div className="btn-container">
                                    <a href="#about" className="btn btn-main"> En savoir +</a>
                                    <a href="https://www.amazon.fr/dp/2955047880?m=A2K6JPHK7CB9GK&tag=100aSavoir-21" target="_blank" el="noreferrer" className="btn btn-main-2 ml-0 ml-lg-2 ">Acheter maintenant</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="banner-img">
                                <img src="../img/PremieresMelodies/couverture.webp" alt="Mes Premières Mélodies" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

            {!dispo && 
            <section className="cta-home" id="demo">
                <div className="container">
                    <div className="row justify-content-center bg-main">
                        <div className="col-lg-6">
                            <h2 className="text-lg">Ce livre est actuellement en rupture de stock !</h2>
                            <p className="mt-3 mb-0">Recevez un email lorsque ce livre sera à nouveau disponible dans notre boutique en ligne et recevez toutes les mises à jour de la part de notre équipe.</p>
                        </div>

                        <div className="col-lg-6">
                            <form action="#" className="subscribe-form" onSubmit={buttonDispo}>
                                <div className="form-group">
                                    <input type="text" name="Prenom" className="form-control" placeholder="Prénom"/>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="Email" className="form-control" placeholder="Email"/>
                                </div>

                                <button type="submit" className="btn btn-main-2">Tenez moi informer</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </section>}

            <section className="features" id="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="heading">
                                <span>Le livre des grands et des petits</span>
                                <h2>Un livre, 4 activités</h2>
                                <p>Le livre de comptines n'est pas un livre ordinaire. Outre les comptines très joliment illustrées il contient plein .</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-music-alt"></i></div>
                                <h5>Tablature & partition</h5>
                                <p>Chaque comptine contient la mélodie simplifiée pour que votre enfant puisse apprendre la guitare, mais aussi les accords, pour que vous puissiez les accompagner au chant ou à la guitare. </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-layout-list-large-image"></i></div>
                                <h5> Illustration </h5>
                                <p>Toutes les comptines contiennent des illustrations exclusives adaptées aux enfants. Les couleurs sont vives et agréables pour que votre enfant découvre les comptines d'une autre façon.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-pencil-alt2"></i></div>
                                <h5>Coloriage </h5>
                                <p>Certaines comptines sont volontairement en noir et blanc afin que votre enfant colorie selon ses envies. Ce livre propose ainsi deux formes d'art (dessins/coloriage et musicale)..</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-control-skip-forward"></i></div>
                                <h5>Format audio inclus </h5>
                                <p>Vous n'êtes pas musiciens ou vous souhaitez diffuser les comptines chez vous ? Le livre permet le téléchargement de 3 versions différentes pour chaque comptine.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {dispo && 
            <section className="cta-home" id="demo">
                <div className="container">
                    <div className="row justify-content-center bg-main">
                        <div className="col-lg-6">
                            <h2 className="text-lg">Téléchargez Mes Premières Mélodies</h2>
                            <p className="mt-3 mb-0">Pour recevoir vos comptines illustrées gratuitement, merci d'indiquer votre email. Nous vous enverrons alors immédiatement un lien pour télécharger une démo du livre !</p>
                        </div>

                        <div className="col-lg-6">
                            <form action="#" className="subscribe-form" onSubmit={buttonDemo}>
                                <div className="form-group">
                                    <input type="text" name="Prenom" className="form-control" placeholder="Prénom"/>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="Email" className="form-control" placeholder="Email"/>
                                </div>

                                <button type="submit" className="btn btn-main-2">Obtenir mon livre gratuitement</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </section>}
            

            <section className="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="book-preview">
                                <img src="../img/PremieresMelodies/kindle.png" className="background-device img-fluid" alt=""/>
                                <div className="owl-book owl-carousel owl-theme" style={{opacity: 1, display: 'block'}}>
                                    <div className="book-item">
                                        <img src="../img/PremieresMelodies/01.webp" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6" id="about">
                            <div className="section-heading book-info mt-lg-0 pl-4">
                                <h2 className="text-lg mb-4">Des partitions destinées à tous</h2>

                                <div className="about-features mt-5">
                                    <div className="about-item mb-40">
                                        <p>Afin de pouvoir être jouées par les jeunes enfants, les partitions sont volontairement simples. Les notes utilisées reviennent fréquemment pour un apprentissage par le jeu. Les partitions contiennent un nombre limité d'accords facilement accessibles pour les plus jeunes. De plus, chaque comptine est écrite dans la même tonalité. En tout et pour tout, moins d'une dizaine d'accords sont utilisés dans le livre et celui-ci possède un petit chapitre pour lire et jouer les tablatures.</p>
                                    </div>
                                    <div className="about-item mb-40">
                                        <h5>Fichiers audio inclus</h5>
                                        <p>Télécharger gratuitement les fichiers audio de chaque comptine inclus dans le livre. Chaque comptine comprend plusieurs versions audio, dont la version chantée, la version karaoké et la version sans guitare pour vous permettre de jouer et chanter avec votre enfant.</p>
                                    </div>
                                    {/* <div className="about-item">
                                        <h5>Evergreen Marketing Goals</h5>
                                        <p>Possimus debitis ab ipsum a autem sit ducimus voluptatem, quos. Consequatur non eum facilis, ex repellendus, vitae debitis.</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>	
{/* 
            <section className="cta-3">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="cta-content text-center">
                                <span>Signup now to receive latest news & exclusive ticket offers.</span>
                                <h2>Don’t miss event of Grand Central Publishing.</h2>
                                <a href="#" target="_blank" className="btn btn-white mt-2"> <i className="fab fa-amazon mr-2"></i> on Amazon</a>
                                <a href="#" target="_blank" className="btn btn-main-2 mt-2 ml-lg-2"> <i className="fab fa-google-play mr-2"></i> Playstore</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="section chapter-2" id="chapter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="heading text-center">
                                {/* <span className="text-color">Featured topic</span> */}
                                <h2>Inclus avec votre livre</h2>
                                {/* <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="chapter-inner">
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Fichier audio chanté </h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Format partition</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div className="chapter-inner">
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Fichier audio karaoké</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Format tablatures</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div className="chapter-inner">
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Fichier audio sans guitare</h4>
                                    </div>
                                </div>
                                <div className="chapter-item d-flex">
                                    <i className="ti-check"></i>
                                    <div className="content pl-4">
                                        <h4>Paroles intégrales</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="section counter-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item mb-5 mb-lg-0">
                                <i className="ti-world"></i>	
                                <h2 className="count">23,500</h2>
                                <p>Copies Sold</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item  mb-5 mb-lg-0">
                                <i className="ti-face-smile"></i>	
                                <h2 className="count">53,246</h2>
                                <p>Cup Of Coffee</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item  mb-5 mb-lg-0">
                                <i className="ti-bookmark-alt"></i>	
                                <h2 className="count">32,456</h2>
                                <p>Copies Released</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item mb-5 mb-lg-0">
                                <i className="ti-heart"></i>	
                                <h2 className="count">45,522</h2>
                                <p>Happy Readers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Auteur />
        
            {/* <section className="featured-client section-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="heading text-center">
                                <span className="text-color">Featured topic</span>
                                <h2>Featured by them. </h2>
                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center border p-4">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <img src="../img/about/award-1.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-2.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-3.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-4.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="col">
                                        <img src="../img/about/award-2.png" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            
            <Footer />
        </div>
    );
};

export default MesPremieresMelodies;