import React from 'react';
import Header from './includes/Header';
import Footer from './includes/Footer';
import { useMetaTags } from 'react-metatags-hook';

const MentionsLegales = () => {

    useMetaTags({
        title: 'Mentions légales - 100àSavoir',
        // description: 'Votre livre vous permet de télécharger gratuitement les fichiers audio des comptines. Remplissez le formulaire avec votre livre à proximité. L\'activation du code peut prendre quelques heures. Chaque comptine propose 3 versions audio différentes, soit 150 fichiers à télécharger en une seule fois (l\'équivalent de plus de 10 CD).',
        charset: 'utf8',
        lang: 'fr',
        metas: [
         // { name: 'keywords', content: 'données, protection, confidentialité, informatique, liberté, newsletter, internaute, coordonnées, communication, documentation, accord, personnel, droit, modification, suppression, information, email, contact, privé, légal, collecte, utilisation, traitement, commercial, prospection, rectification, mise à jour, consultation, consentement, transparent, éthique, respect, sécurité, personnel, digital, formulaire, déclaration, accès, opposition, légitime, confidentiel, transmission, tiers, partenaire, consentement, enregistrement, garantie, service, communication, transparence' },
          { name: 'robots', content: 'index, follow' },
          // { property: 'og:title', content: 'Qui sommes nous ?' }
        ]
    });

    return (
        <div>
            <Header />
            <section className="section contact " id="contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="heading text-center">
                                <h2>Mentions Legales</h2>
                            </div>
                            <div className="heading text-justify">
                            <p>
                                En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site http://100asavoir.com l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :<br></br>
                                <strong>Propriétaire</strong> : Xavier Flament – 6 rue Jean Jacques Rousseau, 60950 Ermenonville France<br></br>
                                <strong>Créateur</strong> : Xavier Flament<br></br>
                                <strong>Responsable publication</strong> : Xavier Flament<br></br>
                                Le responsable publication est une personne physique ou une personne morale.<br></br>
                                <strong>Responsable marketing</strong> : Xavier Flament<br></br>
                                <strong>Webmaster</strong> : Xavier Flament – webmaster@100asavoir.com<br></br>
                                <strong>Hébergeur</strong> : Amazon Web Services Inc, PO Box 81226, Seattle, WA 981808-1226 – USA <i>(Site web hébergé en France)</i><br></br><br></br>
                            
                                <strong>Responsable du traitement des données</strong> : Xavier Flament – 6 rue Jean Jacques Rousseau, 60950 Ermenonville France<br></br>09 70 46 56 06, contact@100aSavoir.com<br></br>
                                R.C.S/SIRET : 833 393 762 00012<br></br>
                                <strong>Finalité des traitements des données</strong> : Les données enregistrées sur 100àSavoir peuvent permettre un suivi anonyme pour une optimisation du site web. Les coordonnées personnelles sont utilisées pour la livraison des commandes au lieu choisi et à l'information en temps réel de vos commandes (par email et/ou téléphone). Enfin, les adresses email (avec accords) peuvent être utilisées pour l'information sur nos présents ou futurs produits.<br></br>
                                <strong>Durée de conservation </strong> : Dans un objectif légal et avec l'obligation fiscale, les données de commandes sont conservées dans un maximum de 5 ans. Les autres données sont conservées pour une durée de 3 ans..<br></br>
                                <strong>Destinataire des données </strong> : Les données sont conservées par 100àSavoir dans son intégralité. Cependant pour une expérience client optimale certaines de ces données peuvent être transmises à Brevo (newsletter) ou GoogleAnnalytics (analyse du site). Tous nos partenaires sont destinés à servir 100àSavoir et ont interdictions d'utiliser les données pour leurs propres utilisations. Tous nos partenaires repondent à la norme RGPD<br></br>
                                <strong>Modalités de retrait, d'accès et de modification des données</strong> : Vous pouvez consulter, modifier ou suspendre toutes les données vous concernant par simple demande par email à contact@100asavoir.com. Pour la newsletter vous pouvez vous désinscrire directement et instantanée par web.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default MentionsLegales;