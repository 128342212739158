import React, { useState } from 'react';
import Header from './includes/Header';
import Footer from './includes/Footer';
import { notifs } from '../composents/Notifs';
import axios from 'axios';
import { useMetaTags } from 'react-metatags-hook';
import { Crisp } from "crisp-sdk-web";


const Comptines = () => {

    const [askDispo, setAskDispo] = useState(false);

    const apiKeyBrevo = process.env.REACT_APP_BREVO;


    useMetaTags({
        title: 'Téléchargez vos fichier audio - 100àSavoir',
        description: 'Votre livre vous permet de télécharger gratuitement les fichiers audio des comptines. Remplissez le formulaire avec votre livre à proximité. L\'activation du code peut prendre quelques heures. Chaque comptine propose 3 versions audio différentes, soit 150 fichiers à télécharger en une seule fois (l\'équivalent de plus de 10 CD).',
        charset: 'utf8',
        lang: 'fr',
        metas: [
          { name: 'keywords', content: 'mélodie, audio, numérique, comptine, téléchargement, livre, fichier, code, activation, version, musique, enfant, apprentissage, support, cd, collection, pédagogie, digital, multimédia, interactif, écoute, music, ludique, format, ressource, partage, musical, innovation, technique, accès, contenu, synchronisation, livre-audio, didactique, sonore, variante, stockage, éducatif, apprentissage musical, média, transmission, découverte, expérience, pratique, technique, programme, personnalisation, accompagnement, interactivité' },
          { name: 'robots', content: 'index, follow' },
          // { property: 'og:title', content: 'Qui sommes nous ?' }
        ]
    });

    // Création du contact
    const addContact = async(email, prenom, listID = 3) => {
        const response = await axios({
            method: 'post',
            url: 'https://api.brevo.com/v3/contacts',
            data: JSON.stringify({
                email: email,
                // ext_id: 20,
                attributes: {
                    PRENOM: prenom, 
                    // COUNTRIES: ['France']
                },
                emailBlacklisted: false,
                smsBlacklisted: false,
                listIds: [listID],
                updateEnabled: false,
                //smtpBlacklistSender: ['user@example.com']
                }),
            headers: {
                'content-type': 'application/json',
                'api-key': apiKeyBrevo
            }
        })
        .then(response => {
            setAskDispo(true);
            Crisp.session.pushEvent("download", { email: email, prenom: prenom });
            notifs('Votre demande a bien été enregistrée. Un email vient de vous être envoyé avec le lien qui vous permettra de télécharger vos fichiers audio.', 'success');

        })
        .catch(error => {
            notifs(error.response.data.message, 'error');
        });
    };

    // Ajout du contact à la liste
    const addList = async(email, listID = 3) => {
        const response = await axios({
            method: 'post',
            url: 'https://api.brevo.com/v3/contacts/lists/'+listID+'/contacts/add',
            data: JSON.stringify({
                emails: [
                   email
                  ]
                }),
            headers: {
                'content-type': 'application/json',
                'api-key': apiKeyBrevo
            }
        })
        .then(response => {
            setAskDispo(true);
            Crisp.session.pushEvent("download", { email: email });
            notifs('Votre demande a bien été enregistrée. Un email vient de vous être envoyé avec le lien qui vous permettra de télécharger vos fichiers audio.', 'success');
        })
        .catch(error => {
            notifs(error.response.data.message, 'error');
        });
    };

    // Action du bouton de téléchargement
    const buttonDownload = async(e) => {
        e.preventDefault();
        const prenom = e.target.prenom.value;
        const email = e.target.email.value;
        const code = e.target.code.value;

        if (prenom && email && code && prenom != null && email != null && code != null) {
            if (code == "4PPQR83SB2H")
            {
                // On créer le contact et on l'ajoute à la liste
                try {
                    addContact(email, prenom, 30);
                }
                catch (error) {
                    console.error(error);
                }

                // Le contact existe déjà alors on l'ajoute juste à la liste
                if (!askDispo)
                {
                    try {
                        addList(email, 30);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            }
            else
            {
                notifs('Le code est incorrecte. Vous pouvez retrouver ce code à la page 137 de votre livre', 'error');
            }
        }
        else
        {
            notifs('Veuillez renseigner tous les champs', 'error');
        }
    };

    return (
        <div>
            <Header />
            
            <section className="banner-main-3" id="banner-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-md-6">
                            <div className="main-banner-content">
                                <span className="subtitle">Formulaire de récéption de vos fichiers audios</span>
                                <h1>
                                    Téléchargez vos fichiers audios
                                </h1>
                                <p className="mb-3 mt-4">Merci pour votre achat de Mes Premières Mélodies. Grâce à votre livre, vous pouvez télécharger les fichiers audio numériques de toutes les comptines présentes dans le livre. Pour obtenir vos fichiers, il vous suffit simplement de remplir le formulaire ci-dessous en gardant votre livre à proximité. Veuillez enfin noter que l'activation de votre code peut nécessiter plusieurs heures. Chaque comptine contient 3 versions audio différentes. Vous allez donc pouvoir télécharger 150 fichiers en une seule fois (soit plus de 10 CD audio).</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="banner-img">
                            <img src="../img/PremieresMelodies/couverture.webp" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

            <section className="cta-home" id="demo">
                <div className="container">
                    <div className="row justify-content-center bg-main">
                        <div className="col-lg-6">
                            <h2 className="text-lg">Formulaire d'activation</h2>
                            <p className="mt-3 mb-0">Remplissez le formulaire suivant pour recevoir un lien par email qui vous permettra de télécharger l'intégralité des fichiers audios. N'oubliez pas de renseigner le code présent à la page 137 de votre livre.</p>
                        </div>

                        <div className="col-lg-6">
                            <form action="#" className="subscribe-form" onSubmit={buttonDownload}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input type="text" name="Code" id="code" className="form-control" placeholder="Code présent dans le livre"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="Prenom" id="prenom" className="form-control" placeholder="Prénom"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="email" name="Email" id="email" className="form-control" placeholder="Email"/>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-main-2">Recevoir mes fichiers audio</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section className="features" id="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="heading">
                                <span>Inclus avec votre livre</span>
                                <h2>Le Rock à ses titres mythiques ? Les guitaristes ont leur livre</h2>
                                <p>L’incontournable du Rock est une vraie bible pour tous les amoureux de guitare. Par ses tablatures, ce livre est accessible à tous les niveaux et vous suivra partout. .</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-headphone"></i></div>
                                <h5>Chanté</h5>
                                <p>Les comptines sont interprété par une chanteuse et accompagné par un guitariste et des musiciens</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-item">
                                <div className="feature-icon"><i className="ti-music-alt"></i></div>
                                <h5> Karaoké </h5>
                                <p>Versions sans la voix de la chanteuse afin de permetre à votre enfant de chanter ou jouer de la guitare par dessus</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="feature-item">
                            <div className="feature-icon"><i className="ti-podcast"></i></div>
                            <h5>Guitare </h5>
                            <p>Cette version n'inclus pas la chanteuse et est remplacé par la guitare pour une écoute plus douce ou pour permettre à votre enfant de jouer la guitare.</p>
                        </div>
                    </div>
                        
                    </div>
                </div>
            </section>

            
            <Footer />
        </div>
    );
};

export default Comptines;