import React from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { useMetaTags } from 'react-metatags-hook';


const MortsStupides = () => {

    const dispo = true; // true si le livre est disponible, false si le livre est en rupture de stock
     
    const livreTitre = "Des histoires vraies mais incroyables";
    const livreDescription = "Ces histoires burlesques ne manqueront pas de se graver dans vos mémoires. Ce sont également autant de blagues à raconter dans tous les repas de familles ou d'amis. Aucun de ces récits n'est inventé. Ce sont des morts stupides et authentiques, des accidents survenus lors des 25 dernières années...Des accidents invraisemblables mais réels Des cascadeurs qui descendent un pont ferroviaire en s'accrochant aux rails du train, un chasseur écrasé par le gibier qu'il vient de tuer ... La bêtise humaine surprend parfois. Il semble qu'elle n'ait pas de limites.";


    useMetaTags({
        title: '100 morts les plus stupides - Axel Fivermant',
        description: livreDescription,
        charset: 'utf8',
        lang: 'fr',
        metas: [
          { name: 'keywords', content: 'mort, accident, stupidité, insolite, tragédie, ironie, fatalité, malchance, anecdote, histoire, imprudence, chronique, fait divers, ridicule, destin, coïncidence, événement, mésaventure, curiosité, catastrophe, absurdité, statistique, maladresse, erreur, imprévisible, incident, étrangeté, humour noir, circonstance, conséquence, dénouement, surprise, bêtise, négligence, impensable, faux pas, naïveté, inadvertance, témérité, impulsion, danger, risque, avertissement, prévention, inconscience, malheur, récit, documentation, chronologie, hasard, réalité' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: 'Les 100 morts les plus stupides - Axel Fivermant' }
        ]
    });

    return (
        <div>
            <Header />
            
            <section className="banner-main-3" id="banner-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-md-6">
                            <div className="main-banner-content">
                                <span className="subtitle">{dispo ? 'Désormais disponible' : 'Ce livre est actuellement en rupture de stock !'}</span>
                                <h1>
                                    {livreTitre}
                                </h1>
                                <p className="mb-3 mt-4">{livreDescription}</p>

                                <div className="btn-container">
                                    <a href="https://100asavoir.com/index.php?Page=Amazon&id=2" target="_blank" el="noreferrer" className="btn btn-main-2 ml-0 ml-lg-2 ">Acheter maintenant</a>

                                    <a href="https://www.amazon.fr/kindle-dbs/hz/signup?tag=100asavoir-21" target="_blank" el="noreferrer" className="btn btn-main ml-0 ml-lg-2 ">Inclus dans l'abonnement Kindle</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="banner-img">
                                <img src="../img/books/MortsStupides.jpg" alt="Les 100 morts les plus stupides" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

            <section className="book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="book-preview">
                                <img src="../img/PremieresMelodies/kindle.png" className="background-device img-fluid" alt=""/>
                                <div className="owl-book owl-carousel owl-theme" style={{opacity: 1, display: 'block'}}>
                                    <div className="book-item">
                                        <img src="../img/PremieresMelodies/01.webp" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6" id="about">
                            <div className="section-heading book-info mt-lg-0 pl-4">
                                <h2 className="text-lg mb-4">Le livre des curieux</h2>

                                <div className="about-features mt-5">
                                    <div className="about-item mb-40">
                                        <p>Des accidents invraisemblables mais réels Des cascadeurs qui descendent un pont ferroviaire en s'accrochant aux rails du train, un chasseur écrasé par le gibier qu'il vient de tuer ... La bêtise humaine surprend parfois. Il semble qu'elle n'ait pas de limites.</p>
                                    </div>
                                    {/* <div className="about-item mb-40">
                                        <h5>Fichiers audio inclus</h5>
                                        <p>Télécharger gratuitement les fichiers audio de chaque comptine inclus dans le livre. Chaque comptine comprend plusieurs versions audio, dont la version chantée, la version karaoké et la version sans guitare pour vous permettre de jouer et chanter avec votre enfant.</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>	

            <Footer />
        </div>
    );
};

export default MortsStupides;