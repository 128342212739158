import React from 'react';

const Auteur = () => {
    return (
        <div>
            <section className="author section" id="author">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="../img/auteur.jpg" alt="Xavier Flament" className="img-fluid w-100"/>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="author-info pl-4 mt-5 mt-lg-0">
                                <div className="heading">
                                    <span className="text-color">À propos de l'auteur</span>
                                    <h2>Xavier Flament</h2>
                                    <div className="follow">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a href="https://www.amazon.fr/stores/Xavier-Flament/author/B00OLZZQ04?tag=100asavoir-21" target="_blank"><i className="ti-amazon"></i></a>
                                            </li>
                                            {/* <!-- <li className="list-inline-item">
                                                <a href="#"><i className="ti-linkedin"></i></a>
                                            </li> --> */}
                                        </ul>
                                    </div>
                                </div>

                                <p className="mb-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. et inventore dicta quos ducimus, consectetur culpa dolore quisquam ipsum facere, fugiat. Corporis eaque, sint.</p>
                                {/* <img src="../img/about/2.png" alt="" className="img-fluid"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Auteur;