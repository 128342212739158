import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export const notifs = (message, type = 'error', position = 'top-end') => {
    /*  withReactContent(Swal).fire({
        icon: error ? 'error' : 'success',
        title: message,
        timer: 2000,
        showConfirmButton: false,
      // inputValue,
    }) */
    const duration = (message.length / 100) * 6000 <= 5000 ? 5000 : (message.length / 100) * 6000;

    withReactContent(Swal).fire({
        toast: true,
        icon: type,
        title: message,
        position: 'top-end',
        timer: duration,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      // inputValue,
    });
}