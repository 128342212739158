import React from 'react';
import Header from './includes/Header';
import Footer from './includes/Footer';
import { useMetaTags } from 'react-metatags-hook';

const Confidentialites = () => {

    useMetaTags({
        title: 'Politique de confidentialité - 100àSavoir',
        // description: 'Votre livre vous permet de télécharger gratuitement les fichiers audio des comptines. Remplissez le formulaire avec votre livre à proximité. L\'activation du code peut prendre quelques heures. Chaque comptine propose 3 versions audio différentes, soit 150 fichiers à télécharger en une seule fois (l\'équivalent de plus de 10 CD).',
        charset: 'utf8',
        lang: 'fr',
        metas: [
          { name: 'keywords', content: 'données, protection, confidentialité, informatique, liberté, newsletter, internaute, coordonnées, communication, documentation, accord, personnel, droit, modification, suppression, information, email, contact, privé, légal, collecte, utilisation, traitement, commercial, prospection, rectification, mise à jour, consultation, consentement, transparent, éthique, respect, sécurité, personnel, digital, formulaire, déclaration, accès, opposition, légitime, confidentiel, transmission, tiers, partenaire, consentement, enregistrement, garantie, service, communication, transparence' },
          { name: 'robots', content: 'index, follow' },
          // { property: 'og:title', content: 'Qui sommes nous ?' }
        ]
    });

    return (
        <div>
            <Header />
            <section className="section contact " id="contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="heading text-center">
                                <h2>Politique de confidentialité </h2>
                            </div>
                            <div className="heading text-justify">
                            Les informations que 100àSavoir serait susceptible de réunir résultent de l’enregistrement volontaire de coordonnées fournies par l’internaute lui permettant de recevoir une documentation, un devis ou de se renseigner sur un point quelconque. En aucun cas, les données recueillies sur le site 100àSavoir ne seront cédées ou vendues à des tiers. Aucune adresse email ne sera transmise à des tiers y compris à nos partenaires sauf avec l’accord écrit des intéressés. Les données collectées par l’intermédiaire de la newsletter sont destinées à l’usage exclusif du responsable de 100àSavoir.<br></br>
                            Droit de modification ou suppression de données personnelles Conformément à la loi « Informatique et Libertés » du 6 janvier 1978, vous disposez d’un droit d’accès, d’interrogation et de rectification qui vous permette, le cas échéant, de faire modifier, compléter, mettre à jour ou effacer les données personnelles vous concernant. Vous disposez également d’un droit d’opposition au traitement de ses données pour des motifs légitimes ainsi qu’un droit d’opposition à ce que ces données soient utilisées à des fins de prospection commerciale.<br></br>
                            Vous pouvez, à tout moment, demander à exercer ce droit en nous contactant par mail, ou via le formulaire de contact.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Confidentialites;